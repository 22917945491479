<template>
  <div>
    <div style="display: flex; align-items: center; justify-content: space-between">
      <el-page-header @back="back" :content="type == 'video' ? '新增视频' : '新增文件'">
      </el-page-header>
      <el-button type="primary" @click="onSubmit"> 保存 </el-button>
    </div>
    <el-form label-width="80px">
      <el-form-item label="文件名" required>
        <div style="display: flex">
          <el-input v-model="filename" style="width: 300px"></el-input>
        </div>
      </el-form-item>
      <template v-if="type == 'video'">
        <el-form-item label="封面图">
          <div v-if="cover.length" style="display: flex; position: relative">
            <el-image
              :src="cover"
              fit="contain"
              :preview-src-list="[cover]"
              style="width: 200px; height: 160px"
            >
            </el-image>
            <div class="image-hover">
              <i class="el-icon-delete delete" @click="handleRemoveImage"></i>
            </div>
          </div>
          <el-upload
            v-else
            class="uploader"
            action="cover"
            :before-upload="uploadCover"
            v-loading="coverUploading"
          >
            <i class="el-icon-plus uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="视频">
          <div style="display: flex">
            <video v-if="videoList.length" controls height="200" width="300">
              <source
                v-for="(item, index) in videoList"
                :key="index"
                :src="item.playURL"
                type="video/mp4"
                :lable="item.definition"
              />
            </video>
            <div v-else style="display: flex; flex-direction: column">
              <el-upload
                action=""
                :before-upload="uploadVideo"
                v-loading="videoUploading"
                accept="video/mp4"
              >
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">只能上传mp4文件</div>
              </el-upload>
              <el-progress
                :text-inside="true"
                :stroke-width="20"
                :percentage="percentage"
              ></el-progress>
            </div>
          </div>
        </el-form-item>
      </template>
      <template v-else>
        <el-form-item label="文件">
          <div style="display: flex; align-items: flex-start">
            <el-upload
              action=""
              :before-upload="uploadDocument"
              v-loading="fileUploading"
              accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div v-if="filePath.length">{{ filePath }}</div>
              <div slot="tip" class="el-upload__tip">
                只能上传文档文件（.pdf、.doc、.xls等）
              </div>
            </el-upload>
          </div>
        </el-form-item>
      </template>
    </el-form>
  </div>
</template>

<script>
import FileUtil from "../../utils/FileUtil";
import CourseApi from "../../request/CourseApi";
export default {
  data() {
    return {
      type: "",
      cover: "",
      coverUploading: false,
      videoList: [],
      videoUploading: false,
      stsInfo: {},
      videoId: "",
      percentage: 0,
      duration: 0,
      filename: "",
      fileUploading: false,
      filePath: "",
      fileSize: 0,
    };
  },
  mounted() {
    this.type = this.$route.query.type;
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    uploadCover(file) {
      this.uploadFile(file, "cover");
    },
    uploadDocument(file) {
      this.uploadFile(file, "document");
    },
    async uploadFile(file, type) {
      if (type == "cover") {
        this.coverUploading = true;
      } else {
        this.fileUploading = true;
        this.fileSize = file.size;
      }
      const result = await FileUtil.uploadFile(
        {
          file: file,
          dir: type,
          rename: true,
          suffix:
            type == "cover" ? "jpg" : file.name.substring(file.name.lastIndexOf(".") + 1),
        },
        this.$http
      );
      this.coverUploading = false;
      this.fileUploading = false;
      if (result.success) {
        if (type == "cover") {
          this.cover = result.url;
        } else {
          this.filePath = result.path;
        }
      }
    },
    handleRemoveImage() {
      this.cover = "";
    },
    async uploadVideo(file) {
      this.videoUploading = true;
      let videoInfo = await FileUtil.getVideoInfo(file);
      this.duration = videoInfo.duration * 1000;
      let uploader = await this.createUploader();
      var userData = '{"Vod":{}}';
      uploader.addFile(file, null, null, null, userData);
      uploader.startUpload();
    },
    async createUploader() {
      let result = await this.$http.post("/zp-mobile/v1/vod/sts", {});
      this.stsInfo = result.data;
      let that = this;
      var uploader = new AliyunUpload.Vod({
        //userID，必填，您可以使用阿里云账号访问账号中心（https://account.console.aliyun.com/），即可查看账号ID
        userId: "1561030994181779",
        //上传到视频点播的地域，默认值为'cn-shanghai'，
        //eu-central-1，ap-southeast-1
        region: that.stsInfo.region,
        //分片大小默认1 MB，不能小于100 KB（100*1024）
        partSize: 1048576,
        //并行上传分片个数，默认5
        parallel: 5,
        //网络原因失败时，重新上传次数，默认为3
        retryCount: 3,
        //网络原因失败时，重新上传间隔时间，默认为2秒
        retryDuration: 2,
        //开始上传
        onUploadstarted: function (uploadInfo) {
          uploader.setSTSToken(
            uploadInfo,
            that.stsInfo.accessKey,
            that.stsInfo.secretKey,
            that.stsInfo.securityToken
          );
        },
        //文件上传成功
        onUploadSucceed: function (uploadInfo) {
          that.videoUploading = false;
          that.videoId = uploadInfo.videoId;
          that.getVideoPlayInfo();
        },
        //文件上传失败
        onUploadFailed: function (uploadInfo, code, message) {
          that.videoUploading = false;
          that.$message.info(message);
        },
        //文件上传进度，单位：字节
        onUploadProgress: function (uploadInfo, totalSize, loadedPercent) {
          that.percentage = Math.floor(loadedPercent * 100);
        },
        //上传凭证或STS token超时
        onUploadTokenExpired: function (uploadInfo) {
          that.$http.post("/zp-mobile/v1/vod/sts", {}).then((res) => {
            if (res.success) {
              that.stsInfo = res.data;
              uploader.resumeUploadWithSTSToken(
                res.data.accessKey,
                res.data.secretKey,
                res.data.securityToken,
                res.data.expiration
              );
            }
          });
        },
        //全部文件上传结束
        onUploadEnd: function (uploadInfo) {},
      });
      return uploader;
    },
    getVideoPlayInfo() {
      CourseApi.videoPlayInfo({
        vodId: this.videoId,
        duration: this.duration,
      }).then((res) => {
        if (res.success) {
          this.videoList = res.data.videoList;
        }
      });
    },
    onSubmit() {
      if (!this.filename.length) {
        this.$message.info("请输入文件名");
        return;
      }
      if (this.type == "video") {
        if (!this.videoId.length) {
          this.$message.info("请上传视频");
          return;
        }
        var param = {
          vid: this.videoId,
          filename: this.filename,
          duration: this.duration,
        };
        if (this.cover.length) {
          param.cover = this.cover;
        }
        CourseApi.saveCourseVideo(param).then((res) => {
          if (res.success) {
            this.$message.success("保存课程视频成功");
            this.back();
          }
        });
      } else {
        if (!this.filePath.length) {
          this.$message.info("请上传文件");
          return;
        }
        CourseApi.addFile({
          filename: this.filename,
          path: this.filePath,
          size: this.fileSize,
        }).then((res) => {
          if (res.success) {
            this.$message.success("保存文件资源成功");
            this.back();
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  // overflow: hidden;
  width: 200px;
  height: 160px;
}
.uploader:hover {
  border-color: #409eff;
}
.uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 160px;
  line-height: 160px;
  text-align: center;
}
.uploader-icon:hover {
  color: #409eff;
}
.image-hover {
  position: absolute;
  width: 200px;
  height: 35px;
  bottom: 0;
  .delete {
    display: none;
  }
  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.3);
    .delete {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 5px;
      color: white;
      font-size: 24px;
    }
  }
}
</style>
